import React from "react";
import { Box, Heading, Text } from "grommet";

export default () => {
  return (
    <Box fill justify="center" direction="row" wrap>
      <Box
        gap="medium"
        align="center"
        direction="column"
        width="960px"
        margin="medium"
      >
        <Heading level="2" textAlign="center">
          Something went wrong.
        </Heading>
        <Text textAlign="center">
          Please contact us at <strong>hello@frontity.org</strong>.
        </Text>
      </Box>
    </Box>
  );
};
