import { createOvermind, IConfig, OnInitialize } from "overmind";
import { createHook } from "overmind-react";
import { parse } from "query-string";
import state from "./state";
import actions from "./actions";
import effects from "./effects";

declare module "overmind" {
  // tslint:disable:interface-name
  interface Config extends IConfig<typeof config> {}
}

const onInitialize: OnInitialize = async ({ state, actions }) => {
  const { email, list } = parse(location.search);
  state.email = email as string;
  state.list = list as string;
  state.route = location.pathname;
  actions.getLists();

  window.addEventListener("popstate", () => {
    state.route = location.pathname;
  });
};

const config = {
  state,
  actions,
  effects,
  onInitialize
};

const overmind = createOvermind(config);

export default createHook(overmind);
