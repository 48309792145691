import React, { useEffect } from "react";
import styled from "styled-components";
import { Box, Heading, Text, CheckBox, Button } from "grommet";
import useStore from "../store";
import FrontityIcon from "./icon";

export default () => {
  const { state, actions } = useStore();

  useEffect(() => {
    if (state.syncState === "error") {
      actions.goToError();
    }
  }, [state.syncState]);

  return !state.subscriptionsUpdated ? (
    <Box fill justify="center" direction="row" wrap>
      <Box
        gap="medium"
        align="center"
        direction="column"
        width="960px"
        margin="medium"
      >
        <Heading level="2" textAlign="center">
          Manage your subscriptions
        </Heading>
        {state.syncState === "synced" && (
          <>
            <Box gap="small" width="530px" margin={{ bottom: "30px" }}>
              {Object.entries(state.lists).map(list => (
                <CheckBox
                  disabled={state.unsubscribedFromAll}
                  key={list[0]}
                  checked={list[1].subscribed}
                  label={`${list[1].name} (${list[1].description})`}
                  onChange={() =>
                    actions.changeSubscription([list[0], !list[1].subscribed])
                  }
                />
              ))}
              <Divider />
              <CheckBox
                checked={state.unsubscribedFromAll}
                label={"Unsubscribe from all."}
                onChange={() =>
                  actions.changeSubscription(["all", state.unsubscribedFromAll])
                }
              />
            </Box>
            <Button
              primary
              color="brand"
              label="Save"
              icon={<FrontityIcon />}
              onClick={actions.updateSubscriptions}
            />
          </>
        )}
        {state.syncState === "syncing" && <Text>Loading...</Text>}
      </Box>
    </Box>
  ) : (
    <Box fill justify="center" direction="row" wrap>
      <Box
        gap="medium"
        align="center"
        direction="column"
        width="960px"
        margin="medium"
      >
        <Heading level="2" textAlign="center">
          Your subscription options have been saved.
        </Heading>
        <Button
          primary
          color="brand"
          label="Back to subscriptions"
          icon={<FrontityIcon />}
          onClick={actions.goToManage}
        />
      </Box>
    </Box>
  );
};

const Divider = styled.hr`
  width: 100%;
`;
