import { Derive } from "overmind";

const lists: {
  [key: string]: {
    name: string;
    description: string;
    subscribed?: boolean;
  };
} = {
  "subscription-announcements": {
    name: "Announcements",
    description: "releases, latest framework updates and new features"
  },
  "subscription-newsletter": {
    name: "Newsletter",
    description:
      "blog posts, community news, tutorials, showcases, and other relevant content"
  },
  "subscription-one-to-one": {
    name: "One-to-one",
    description:
      "occasional emails with tips and suggestions to help you get the most out of Frontity and its community"
  }
};

type State = {
  route: string;
  email?: string;
  list?: string;
  lists: typeof lists;
  listExists: Derive<State, boolean>;
  unsubscribedFromList: boolean;
  unsubscribedFromAll: boolean;
  subscriptionsUpdated: boolean;
  syncState: "idle" | "syncing" | "synced" | "error";
};

const state: State = {
  route: "/",
  lists,
  unsubscribedFromList: false,
  unsubscribedFromAll: false,
  subscriptionsUpdated: false,
  listExists: state => !!state.list && !!state.lists[state.list],
  syncState: "idle"
};

export default state;
