import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Grommet } from "grommet";
import useStore from "./store";
import frontityTheme from "./theme";
import Unsubscribe from "./components/unsubscribe";
import Manage from "./components/manage";
import Error from "./components/error";

const App = () => {
  const { state } = useStore();

  let Page = <Error />;
  if (state.route === "/unsubscribe" && state.email && state.listExists) {
    Page = <Unsubscribe />;
  }
  if (state.route === "/manage" && state.email) {
    Page = <Manage />;
  }

  return <Grommet theme={frontityTheme}>{Page}</Grommet>;
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
