import React from "react";
import { Box, Text, Heading, Button } from "grommet";
import useStore from "../store";
import FrontityIcon from "./icon";

export default () => {
  const { state, actions } = useStore();

  return (
    <Box fill justify="center" direction="row" wrap>
      {!state.unsubscribedFromList ? (
        <Box
          gap="medium"
          align="center"
          direction="column"
          width="960px"
          margin="medium"
        >
          <Heading level="2" textAlign="center">
            We are sorry to see you go 😥
          </Heading>
          <Text textAlign="center">
            You are about to unsubscribe <strong>{state.email}</strong> from our{" "}
            <strong>{state.lists[state.list as string].name}</strong>.
          </Text>
          <Button
            primary
            color="brand"
            label="Yes, unsubscribe"
            icon={<FrontityIcon />}
            onClick={actions.unsubscribeFromList}
          />
        </Box>
      ) : (
        <Box
          gap="medium"
          align="center"
          direction="column"
          width="960px"
          margin="medium"
        >
          <Heading level="2" textAlign="center">
            You are now unsubscribed.
          </Heading>
          <Text textAlign="center">
            Please note that you may still receive emails from other lists in
            Frontity.
          </Text>
          <Box width="300px">
            <Button
              primary
              color="brand"
              label="Manage subscriptions"
              icon={<FrontityIcon />}
              onClick={actions.goToManage}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
