import { Action } from "overmind";

const goToManage: Action = ({ state }) => {
  if (state.route !== "/manage") {
    history.pushState({}, "", `/manage?email=${state.email}`);
    state.route = location.pathname;
  }

  state.subscriptionsUpdated = false;
};
const goToError: Action = ({ state }) => {
  if (state.route !== "/") {
    history.pushState({}, "", "/");
    state.route = location.pathname;
  }
};

const unsubscribeFromList: Action = ({ state, actions }) => {
  actions.changeSubscription([state.list as string, false]);
  actions.updateSubscriptions();
  state.unsubscribedFromList = true;
};

const getLists: Action = async ({ state, effects }) => {
  state.syncState = "syncing";

  if (!state.email) {
    state.syncState = "error";
    return;
  }

  try {
    const { lists, unsubscribed } = await effects.getLists(state.email);

    if (!Array.isArray(lists)) {
      state.syncState = "error";
      return;
    }

    state.unsubscribedFromAll = unsubscribed;

    Object.keys(state.lists).forEach(list => {
      if (lists.includes(list)) state.lists[list].subscribed = true;
    });

    state.syncState = "synced";
  } catch (e) {
    state.syncState = "error";
  }
};

const changeSubscription: Action<[string, boolean?]> = (
  { state },
  [list, subscribed]
) => {
  if (list === "all") state.unsubscribedFromAll = !subscribed;
  else {
    state.lists[list].subscribed = subscribed;
  }
};

const updateSubscriptions: Action = ({ state, effects }) => {
  const payload: {
    event: string;
    email: string;
    unsubscribed?: boolean;
    subscriptions?: { [key: string]: boolean };
  } = {
    event: "updateSubscriptions",
    email: state.email as string
  };

  const subscriptions: { [key: string]: boolean } = Object.keys(
    state.lists
  ).reduce((final: { [key: string]: boolean }, current) => {
    if (typeof state.lists[current].subscribed !== "undefined") {
      final[current] = state.lists[current].subscribed as boolean;
    }
    return final;
  }, {});

  payload.subscriptions = subscriptions;

  if (state.syncState === "synced") {
    payload.unsubscribed = state.unsubscribedFromAll;
  }

  effects.dataLayer.push(payload);

  state.subscriptionsUpdated = true;
};

export default {
  unsubscribeFromList,
  goToManage,
  goToError,
  getLists,
  changeSubscription,
  updateSubscriptions
};
