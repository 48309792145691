type DataLayer = {
  event: string;
  [key: string]: any;
};

declare global {
  interface Window {
    dataLayer: DataLayer[];
  }
}

const effects: {
  dataLayer: DataLayer[];
  getLists: (
    email: string
  ) => Promise<{ lists: string[]; unsubscribed: boolean }>;
} = {
  dataLayer: window.dataLayer || [],
  getLists: async email => {
    const response: Response = await fetch(
      `${
        process.env.NODE_ENV !== "production"
          ? "http://localhost:4000/"
          : "/get-subscriptions"
      }?email=${email}`
    );
    return await response.json();
  }
};

export default effects;
